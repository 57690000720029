import { graphql, StaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { Link as ScrollLink } from "react-scroll";
import heroImg from "../assets/images/summit.png";
import bgvideo from "../assets/videos/Bg.mp4";
import CustomAccordion from "../components/common/accordian";
import Buttons from "../components/common/button";
import CarouselComponent from "../components/common/carousel-component";
import FormBuilder from "../components/common/FormBuilder/Form-Builder";
import handleFormSubmit, {
  sendEventHumioLog,
} from "../components/common/FormBuilder/Form-Function";
import Frame from "../components/common/frame";
import SVGIcon from "../components/common/SVGIcon";
import Navigation from "../components/navigation";
import { checkValidEmail } from "../components/utility/validation";
import "../styles/component/faq.scss";
import "../styles/component/pricing-faq.scss";
import "../styles/pages/Supersummit-2022.scss";
// import bgvideo from "../assets/videos/Bg.mp4"
import Laugh from "./../assets/images/laugh.svg";
import Popper from "./../assets/images/party-popper.svg";
import DOMPurify from "dompurify";

const Event = (props) => {
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [email3, setEmail3] = useState("");

  const [nomineeName, setNomineeName] = useState("");
  const [nomineeEmail, setNomineeEmail] = useState("");

  const [email1Error, setEmail1Error] = useState(false);
  const [email2Error, setEmail2Error] = useState(false);
  const [email3Error, setEmail3Error] = useState(false);

  const [success, setSuccess] = useState(false);

  const data = [];

  const searchParams = new URLSearchParams(props.location.search);

  useEffect(() => {
    const parameter1 = sessionStorage.getItem("superSummitName");
    const parameter2 = sessionStorage.getItem("superSummitEmail");
    setNomineeName(parameter1);
    setNomineeEmail(parameter2);
  }, [searchParams]);

  const [icsFile, setIcsFile] = useState();
  useEffect(() => {
    setIcsFile(localStorage.getItem("supersummit_event_icsFile"));
  }, []);

  useEffect(() => {
    if (checkValidEmail(email1) && email1.length > 0) {
      data.push(email1);
    }

    if (checkValidEmail(email2) && email2.length > 0) {
      data.push(email2);
    }

    if (checkValidEmail(email3) && email3.length > 0) {
      data.push(email3);
    }
  }, [email1, email2, email3]);

  const resetError = () => {
    setEmail1Error(false);
    setEmail2Error(false);
    setEmail3Error(false);
  };

  const validate = () => {
    if (email1.length > 0 && !checkValidEmail(email1)) {
      setEmail1Error(true);
      setTimeout(resetError, 4000);
    }

    if (email2.length > 0 && !checkValidEmail(email2)) {
      setEmail2Error(true);
      setTimeout(resetError, 4000);
    }

    if (email3.length > 0 && !checkValidEmail(email3)) {
      setEmail3Error(true);
      setTimeout(resetError, 4000);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    validate();
    if (data.length > 0) {
      data.forEach(function(data, idx) {
        var xhr = new XMLHttpRequest();
        var url = process.env.HUBSPOT_SUPERSUMMIT_LONDON_REFERRAL_ENDPOINT;
        var data = {
          submittedAt: Date.now(),
          fields: [
            {
              name: "nominee_email",
              value:
                nomineeEmail?.length > 0
                  ? DOMPurify.sanitize(nomineeEmail)
                  : "",
            },
            {
              name: "nominee_name",
              value:
                nomineeName?.length > 0 ? DOMPurify.sanitize(nomineeName) : "",
            },
            {
              name: "email",
              value: DOMPurify.sanitize(data),
            },
          ],
          context: {
            pageUri: window.location.href,
            pageName: "Super summit London - success",
          },
        };

        var final_data = JSON.stringify(data);

        sendEventHumioLog(
          final_data,
          {},
          `Before hitting submit from ${document.URL}`
        );

        xhr.open("POST", url);

        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status === 200) {
            setEmail1("");
            setEmail2("");
            setEmail3("");
            resetError();
            setSuccess(true);
          } else if (
            xhr.readyState === 4 &&
            (xhr.status === 400 || xhr.status === 403 || xhr.status === 404)
          ) {
            sendEventHumioLog(
              final_data,
              xhr.responseText,
              `After hitting submit & got failed from ${document.URL}`
            );
            console.log(xhr.responseText);
            setSuccess(true);
          }
        };
        xhr.send(final_data);
        console.log(final_data);
      });
    }
  };

  return (
    <div>
      <StaticQuery
        query={graphql`
          query SuccessPage {
            SuperOps {
              pageComponents(
                where: { pageName: "SUPERSUMMIT - Success page" }
              ) {
                seo {
                  keywords
                  description
                  title
                }

                sectionName
                sectionLink
                html {
                  html
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            {data.SuperOps.pageComponents.map((page) => {
              const { seo, sectionName, sectionLink, html } = page;
              return (
                <div className="Supersummit-2022 Success-page ">
                  <Frame
                    seo={seo}
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                  >
                    <header>
                      <Navigation
                        page="Supersummit-2022"
                        color={"transparent"}
                      />
                    </header>

                    {/* <div className="overflow-hidden">
                    <div className="gradient"></div>
                      <video
                        className="bg-video"
                        autoPlay
                        muted
                        loop
                        id="bg-video"
                      >
                        <source src={bgvideo} type="video/mp4" />
                      </video>
                    </div> */}

                    <section className="Hero position-relative">
                      {/* <video
                        className="bg-video"
                        autoPlay
                        muted
                        loop
                        id="bg-video"
                      >
                        <source src={bgvideo} type="video/mp4" />
                      </video> */}

                      <section className="Form-section position-relative">
                        <Container>
                          <a href="https://superops.com" target="_blank">
                            <SVGIcon
                              name="spsLogoDarkBG"
                              className="top-logo"
                            />
                          </a>
                          <div className="main-box position-relative">
                            <Row className="justify-content-between">
                              <Col lg={5} sm={12}>
                                <div className="heading-box">
                                  <div>
                                    <SVGIcon name="bolt" />
                                  </div>
                                  <div className="heading">
                                    <h2 className="font-roboto">
                                      {sectionName}
                                    </h2>
                                  </div>
                                </div>

                                <div className="money-box">
                                  <div>
                                    <p className="text m-0">
                                      You could win an Amazon voucher worth
                                    </p>
                                  </div>
                                  <div>
                                    <p className="money font-roboto">
                                      {sectionLink}
                                    </p>
                                  </div>
                                </div>
                                {console.log(
                                  "ics",
                                  icsFile,
                                  icsFile ? "true" : "false"
                                )}
                                {icsFile ? (
                                  <a
                                    href={localStorage.getItem(
                                      "supersummit_event_icsFile"
                                    )}
                                    className="mr24"
                                  >
                                    <div className="d-flex mt32 mb50 remainderBox text-grey align-items-center justify-content-center">
                                      <p className="remainderText fw-bold m-0">
                                        ADD EVENT TO CALENDAR
                                      </p>
                                    </div>
                                  </a>
                                ) : null}

                                <div className="p16 text-light-white desc">
                                  <p>{parse(html[0].html)}</p>
                                </div>

                                <div className="TnC">
                                  <p className="text-pink active">
                                    terms & conditions
                                  </p>
                                  <div className="p12 text-light-white">
                                    {parse(html[1].html)}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6} sm={12}>
                                <div className="form-box">
                                  {!success ? (
                                    <>
                                      {" "}
                                      <p className="form-text text-center">
                                        Start referring your friends!
                                      </p>
                                      <div className="form-floating">
                                        <form onSubmit={handleSubmit}>
                                          <div className="position-relative">
                                            <input
                                              name=""
                                              value={email1}
                                              className={`${
                                                email1.length > 0 ? "valid" : ""
                                              } ${email1Error ? "error" : ""}`}
                                              onChange={(e) =>
                                                setEmail1(e.target.value)
                                              }
                                            />
                                            <label
                                              className={`position-absolute ${
                                                email1Error ? "error" : ""
                                              } `}
                                            >
                                              Email address 1{" "}
                                            </label>
                                          </div>

                                          <div className="position-relative">
                                            <input
                                              name=""
                                              value={email2}
                                              className={`${
                                                email2.length > 0 ? "valid" : ""
                                              } ${email2Error ? "error" : ""}`}
                                              onChange={(e) =>
                                                setEmail2(e.target.value)
                                              }
                                            />
                                            <label
                                              className={`position-absolute ${
                                                email2Error ? "error" : ""
                                              }`}
                                            >
                                              Email address 2{" "}
                                            </label>
                                          </div>

                                          <div className="position-relative">
                                            <input
                                              name=""
                                              value={email3}
                                              className={`${
                                                email3.length > 0 ? "valid" : ""
                                              } ${email3Error ? "error" : ""}`}
                                              onChange={(e) =>
                                                setEmail3(e.target.value)
                                              }
                                            />
                                            <label
                                              className={`position-absolute ${
                                                email3Error ? "error" : ""
                                              }`}
                                            >
                                              Email address 3{" "}
                                            </label>
                                          </div>
                                          <Buttons
                                            theme="primary pink w-100"
                                            text="REFER NOW"
                                          />
                                        </form>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="success-box text-center">
                                        <img src={Popper} alt="popper" />

                                        <p className="ty">
                                          Thank you <br /> for referring!
                                        </p>

                                        <div className="p16">
                                          <p>
                                            {" "}
                                            We ain't greedy{" "}
                                            <span>
                                              <img src={Laugh} alt="laugh" />
                                            </span>{" "}
                                            <br /> But we would love for you to
                                            refer more!
                                          </p>
                                        </div>
                                        <Buttons
                                          theme="primary pink w-100 mt48"
                                          text="REFER AGAIN"
                                          onClick={() => setSuccess(!success)}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Container>
                      </section>
                    </section>

                    {/* <section className="Form-section position-relative">
                    
                      <Container>
                        <SVGIcon name="SuperOps-logo" className="top-logo" />
                        <div className="main-box position-relative">
                          <Row className="justify-content-between">
                            <Col lg={5} sm={12}>
                              <div className="heading-box">
                                <div>
                                  <SVGIcon name="bolt" />
                                </div>
                                <div className="heading">
                                  <h2>{sectionName}</h2>
                                </div>
                              </div>

                              <div className="money-box">
                                <div>
                                  <p className="text">
                                    You could win an Amazon voucher worth
                                  </p>
                                </div>
                                <div>
                                  <p className="money">{sectionLink}</p>
                                </div>
                              </div>

                              <div className="p16 text-light-white desc">
                                <p>{parse(html[0].html)}</p>
                              </div>

                              <div className="TnC">
                                <p className="text-pink active">
                                  terms & conditions
                                </p>
                                <div className="p12 text-light-white">
                                  {parse(html[1].html)}
                                </div>
                              </div>
                            </Col>
                            <Col lg={6} sm={12}>
                            <div className="form-box">
                          {!success ? (
                            <>
                              {" "}
                              <p className="form-text text-center">
                                Start referring your friends!
                              </p>
                              <div className="form-floating">
                                <form onSubmit={handleSubmit}>
                                  <div className="position-relative">
                                    <input
                                      name=""
                                      value={email1}
                                      className={`${
                                        email1.length > 0 ? "valid" : ""
                                      } ${email1Error ? "error" : ""}`}
                                      onChange={e => setEmail1(e.target.value)}
                                    />
                                    <label
                                      className={`position-absolute ${
                                        email1Error ? "error" : ""
                                      } `}
                                    >
                                      Email address 1{" "}
                                    </label>
                                  </div>

                                  <div className="position-relative">
                                    <input
                                      name=""
                                      value={email2}
                                      className={`${
                                        email2.length > 0 ? "valid" : ""
                                      } ${email2Error ? "error" : ""}`}
                                      onChange={e => setEmail2(e.target.value)}
                                    />
                                    <label
                                      className={`position-absolute ${
                                        email2Error ? "error" : ""
                                      }`}
                                    >
                                      Email address 2{" "}
                                    </label>
                                  </div>

                                  <div className="position-relative">
                                    <input
                                      name=""
                                      value={email3}
                                      className={`${
                                        email3.length > 0 ? "valid" : ""
                                      } ${email3Error ? "error" : ""}`}
                                      onChange={e => setEmail3(e.target.value)}
                                    />
                                    <label
                                      className={`position-absolute ${
                                        email3Error ? "error" : ""
                                      }`}
                                    >
                                      Email address 3{" "}
                                    </label>
                                  </div>
                                  <Buttons
                                    theme="primary pink w-100"
                                    text="REFER NOW"
                                  />
                                </form>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="success-box text-center">
                                <img src={Popper} alt="popper" />

                                <p className="ty">
                                  Thank you <br /> for referring!
                                </p>

                                <div className="p16">
                                  <p>
                                    {" "}
                                    We ain't greedy{" "}
                                    <span>
                                      <img src={Laugh} alt="laugh" />
                                    </span>{" "}
                                    <br /> But we would love for you to refer
                                    more!
                                  </p>
                                </div>
                                <Buttons
                                  theme="primary pink w-100 mt48"
                                  text="REFER AGAIN"
                                  onClick={() => setSuccess(!success)}
                                />
                              </div>
                            </>
                          )}
                        </div>

                            </Col>
                          </Row>
                        </div>
                      </Container>
                    </section> */}
                  </Frame>
                </div>
              );
            })}
          </>
        )}
      />
    </div>
  );
};

export default Event;
